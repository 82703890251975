.twoBoxes{
    width: 50%;
    padding: 10px;
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
}

.price{
    width: 100%;
    border-color: #000000;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-left: 2px solid #000000;
    border-bottom: 2px solid #000000;
    border-right: 2px solid #000000;
}
.heading {
    font-family: sans-serif;
    margin: auto;
    color: #000000;
    text-align: center;
    font-size: 30px;
    max-width: 600px;
    margin-top: 0px;
    background-color: white;
    position: sticky;
}