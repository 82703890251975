/* DetailsTicketingIP.css */

.details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 60px;
  }
  
  .details-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .details-paper {
    width: 90vw;
    max-width: 80vw;
    padding: 16px;
  }
  
  .details-field {
    margin-bottom: 8px;
  }
  
  .details-vehicle,
  .details-visitor {
    padding: 16px;
    max-width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  
  .buttons-container {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 20px;
  }
  