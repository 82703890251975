.reservation-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  font-family: sans-serif !important;
  font-size: 15px;
  background-color: #c6c5c5 !important ;
  padding: 20px;
  margin :10px 10px !important;
  border-radius: 8px;
}
label
{
  font-size: 15px !important;
}
.reservation-form__left,
.reservation-form__right {
  width: 100%;
  max-width: 500px;
}

.reservation-form__left h2 {
  margin-bottom: 20px;
}

.reservation-form__left form {
  display: flex;
  flex-direction: column;
}

.reservation-form__left label {
  margin-top: 10px;
 
}

.reservation-form__left input,
.reservation-form__left select,
.reservation-form__right input,
.reservation-form-middle input,
.reservation-form-middle select,
.reservation-form__right select {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 15px;
 
}

.radioBtn {
  padding: 1px;
  border: none;
  border-radius: 5px;
  width: fit-content !important;
}

.radioInput {
  width: fit-content;
  display: flex;
}
input {
  background-color: #ffffff;

}
.proceedBtn {
  background-color: #1e3932;
  color: white;
  margin-left: 55%;
  width: 45%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  color:white;
}

.reservation-form__right h2, .reservation-form-middle h2{
  margin-bottom: 20px;
  text-align: center;
}

.reservation-form__right label ,.reservation-form-middle label {
  margin-top: 10px;
  
}

.reservation-form__right p {
  margin-top: 5px;
}

.reservation-form__right button {
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20%;
}

.reservation-form__right button:hover ,.reservation-form-middle button:hover{
  background-color: #333;
}
.reservation-form-middle
{
  width: 50%;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  margin: auto;
  margin-top: 5%;
}
.inline
{
  display: flex;
  
}
.spaceleft
{
  margin-left: 5px !important;
}
.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
}

.button {
  padding: 10px 20px;
  background-color: #1e3932;
  border: none;
  margin: 0 10px;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  color:white;
  width: 30%;
}

.button:disabled {
  background-color: #ddd;
}

.back-button a {
  display: inline-block;
  padding: 8px 16px;
  background-color: #1e3932;
  color: #fff;
  font-family: sans-serif !important;
  font-size: 15px;
  
}

input[readonly] {
  background-color: #677571;
}

.reservation-form__left input[type="submit"],
.reservation-form__right input[type="submit"],
.reservation-form-middle input[type="submit"],
.reservation-form__right button {
  background-color: #1e3932;
  color: white;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .reservation-form__left,
  .reservation-form__right {
    width: 45%;
    max-width: none;
  }

  .reservation-form__right {
    margin-left: 10%;
  }

  .reservation-form__right button {
    margin-top: 10%;
  }
  .proceedBtn {
    background-color: #1e3932;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 15px;
    color:white;
  }
  
}
@media only screen and (max-width: 600px) {
  .back-button {
    position: relative !important;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .back-button a {
    padding: 10px 16px;
    font-size: 14px;
  }
}