/* Common styles */
.reservation-form,
.reservation-form__left,
.reservation-form__right,
.reservation-form-middle {
  font-family: sans-serif;
  font-size: 15px;
}

.reservation-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #c6c5c5;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
}

.reservation-form-new-left,
.reservation-form__right {
  width: 100%;
  max-width: 500px;
}

.reservation-form__left h2,
.reservation-form__right h2,
.reservation-form-middle h2 {
  margin-bottom: 20px;
  text-align: center;
}

.reservation-form__left form {
  display: flex;
  flex-direction: column;
}

.reservation-form__left label,
.reservation-form__right label,
.reservation-form-middle label {
  margin-top: 10px;
}

.reservation-form__left input,
.reservation-form__left select,
.reservation-form__right input,
.reservation-form-middle input,
.reservation-form-middle select,
.reservation-form__right select,
.reservation-form__left input[type="submit"],
.reservation-form__right input[type="submit"],
.reservation-form-middle input[type="submit"],
.reservation-form__right button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 15px;
}

.radioBtn,
.radioInput {
  padding: 1px;
  border: none;
  border-radius: 5px;
  width: fit-content;
}

.input {
  background-color: #ffffff;
}

.proceedBtn {
  background-color: #1e3932;
  color: white;
  margin-left: 55%;
  width: 45%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
}

.reservation-form__right p,
.reservation-form__right button,
.reservation-form-middle button,
.reservation-form-middle p {
  margin-top: 5px;
}

.reservation-form__right button,
.reservation-form-middle button {
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20%;
}

.reservation-form__right button:hover,
.reservation-form-middle button:hover {
  background-color: #333;
}

.reservation-form-middle {
  width: 70%;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  margin: auto;
  margin-top: 5%;
}

.inline {
  display: flex;
}

.spaceleft {
  margin-left: 5px;
}

.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 5px;
}

.button {
  padding: 10px 20px;
  background-color: #1e3932;
  border: none;
  margin: 0 10px;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
  color: white;
  width: 30%;
}

.button:disabled {
  background-color: #ddd;
}

.back-button {
  width: fit-content;
}

.form-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back-button a {
  display: inline-block;
  padding: 8px 16px;
  background-color: #1e3932;
  color: #fff;
  font-family: sans-serif;
  font-size: 15px;
}
.proceed-btn, 
.home-btn{
  background-color: #1e3932;
  color: white;
}

.proceed-btn.disabled{
  background-color: #c6c5c5;
  color: #f2f2f2;
  cursor: not-allowed;
}

.reservation-form-new-left input,
.reservation-form-new-left select
{
  width: auto;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 15px;
}
.multiple-nationalities-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.multiple-nationalities-input label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
}

.multiple-nationalities-input input,
.multiple-nationalities-input select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.multiple-nationalities-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-row {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  max-height: 50px;
}

.custom-table, .custom-header, .custom-cell {
  border: 1px solid #ccc;
}

.custom-header, .custom-cell {
  padding: 8px;
  text-align: center;
}
/* Button styles */
.add-button{
  background-color: #1e3932;
  color: white;
  border: none;
  font-size: 22px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.remove-button {
  background: #1e3932;
  color: white;
  border: none;
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  height: 50%;
  max-width:min-content;
  line-height: 20px;
}

.add-button:hover, .remove-button:hover {
  opacity: 0.8;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
}

.table-container::-webkit-scrollbar {
  width: 2px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 5px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #d3d3d3;
}

.checkboxInput{
  display: flex;
  justify-content: flex-start;
}
.checkboxInput input{
  width: auto;
}
/* Media queries */
@media only screen and (min-width: 768px) {
  .reservation-form__left,
  .reservation-form__right {
    width: 45%;
    max-width: none;
  }

  .reservation-form__right {
    margin-left: 10%;
  }

  .reservation-form__right button {
    margin-top: 10%;
  }

  .proceedBtn {
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .multiple-nationalities-input label{
    font-family: sans-serif;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .back-button {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }

  .back-button a {
    padding: 10px 16px;
    font-size: 14px;
  }
  .multiple-nationalities-input label{
    font-family: sans-serif;
    font-size: 14px;
  }
  .reservation-form-middle {
    width: 100%;
  }
}
