.Donationform {
  width: 93%;
  padding: 10px;
  border: 2px solid #0a5c3a;
  margin: 10px auto;
  border-radius: 20px;
  overflow: auto;
}
.VisiterDetails {
  margin-top: 1em;
}
.VisiterDetails h3 {
  margin-bottom: 0.5em;
}
.headingDonation {
  margin-bottom: 0.5em;
}

.heading {
  font-family: sans-serif;
  margin: auto;
  color: #000000;
  text-align: center;
  font-size: 30px;
  max-width: 600px;
  margin-top: 0px;
  background-color: white;
  position: sticky;
}

.userDetilasTxt {
  display: flex;
  flex-direction: column;
  width: 32%;
}

.userHeading {
  margin: 0px;
  font-size: 0.875rem;
  line-height: 1.57;
  font-family: 'Public Sans', sans-serif;
  font-weight: 400;
  color: rgb(140, 140, 140);
}

.headingdetails {
  font-size: 22px;
  text-decoration: underline; 
}
.headingDonation {
  font-size: 22px; 
  text-decoration: underline;
}
.submitButton {
  float: right; 
  margin-right: 10px; 
}
.DonationOption {
  border: 2px solid #03402e;
  border-radius: 10px;
  display: grid;
  place-items: center;
  height: 70vh;
  width: 90%;
  height: 50vh;
  min-height: 70vh;
  background-color: rgba(236, 244, 240, 0.944);
  margin: 0 auto;
  box-sizing: border-box; 
}
label {
  font-family: sans-serif;
  font-size: 18px;
}

input[type='text'] {
  font-family: sans-serif;
  font-size: 17.5px;
}
#bootstrap-overrides .phone-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60%;
  border: 1px solid #ced4da;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.944) !important;
}

#bootstrap-overrides .phone-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .Donationform {
    width: 90%;
    padding: 5px;
  }

  .userDetilasTxt {
    width: 100%;
    margin-bottom: 1em;
  }

  .userHeading {
    font-size: 1rem;
  }

  .submitButton {
    float: none;
    margin: 10px auto;
  }
}
