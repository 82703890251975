.rootUser {
    width: 100%;
    padding: 10px;
    border: 2px solid #000000;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 20px;
}

.heading {
    font-family: sans-serif;
    margin: auto;
    color: #000000;
    text-align: center;
    font-size: 30px;
    max-width: 600px;
    margin-top: 0px;
    background-color: white;
    position: sticky;
}

.userDetilasTxt {
    display: flex;
    flex-direction: column;
    width: 32%;
}

.userHeading {
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.57;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    color: rgb(140, 140, 140);
}

.userData {
    font-size: 0.875rem;
    line-height: 1.57;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
}