.textfield {
  width: 200px;
  margin-right: 1em;
}
input.cash-received {
  background-color: #e0e0e0;
  border-radius: 10px 10px 0 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  padding: 10px;
  width: 100%;
  height: 49px;
  content: "";
  display: block;
  border-bottom: 0.05px dotted #828282;
 
}