.main {
    display:  flex;
    height:  100%;
    width:  100%;
    margin-left: 10px;
    margin-right:  30px;
    margin-top: 60px;
}

.sideBar {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    width: 15%;
    margin-right: 12px;
    padding: 12px;
    border: 1px solid #eaeaea !important;
    border-radius: 4px;
    min-height: 85vh;
}

.sideBarButton {
    background: #f5f5f5;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
}

.sideBarButton:hover {
    background: #1E3932;
    color: #f5f5f5 !important;
}

.activeLink{
    background: #1E3932;
    color: #f5f5f5 !important;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
}

.body{
    width: 83%;
    height:100%;
    border: 1px solid #eaeaea !important;
    border-radius: 4px;
    padding: 12px;
    margin-right: 30px;
}
